import _ from "lodash";
import {DATE_FORMAT_MONTH_MMM, parseDate} from "../../helpers/helpers";

export abstract class CalendarLayout {
  addLabel = false;

  // Used to draw the elements
  drawer: Drawer<any>;

  // Values
  pad?: any;
  dividerMonth?: any;
  dividerWeek?: any;
  dividerDay?: any;

  collectWeeksSeparatedbyMonths: Function = this.enableWeekSeparationByMonths;

  dateTransformFn: Function = this.dateTransformFunction;

  constructor(drawer: Drawer<any>) {
    this.drawer = drawer;
    this.enableWeekSeparationByMonths();
  }

  //
  dateTransformFunction(entry: any){
      const month = parseDate(entry).month();
      let week = parseDate(entry).isoWeek();
      // Special case: the last month wraps over to a new week (for next year)
      if (week===1 && month===11) {
        week = 53;
      }
      return {
        key: entry,
        date: parseDate(entry).date(),
        week: week,
        month: month,
        monthName: parseDate(entry).format(DATE_FORMAT_MONTH_MMM),
        year: parseDate(entry).year(),
      };
  }

  enableWeekSeparationByMonths() {
    this.collectWeeksSeparatedbyMonths = (entry: any) => [entry.week, entry.month]
  }

  disableWeekSeparationByMonths() {
    this.collectWeeksSeparatedbyMonths = (entry: any) => [entry.week]
  }

  groupCalendarEntriesFromRange(rangeOfDays: any): Array<any> {
    // Apply the transform to all dates
    // ["2020-01-01" => {key: "2020-01-01", date: 1, week: 0, month: 1,  ...}]
    const allEntries = rangeOfDays.map(this.dateTransformFn);

    // Group all the week, but if month changes within a week then split
    // that ween into two
    // @ts-ignore
    const collectGroupedWeeksByMonth = (entry: Array<unknown>) => _.first(entry).month;

    // First make a row out of weeks (weeks with months
    // changing in between are split into two)
    //
    const weeks = _.groupBy(allEntries, this.collectWeeksSeparatedbyMonths);
    // Group the week rows based on the month
    //
    // Each month has a list of weeks inside of it
    const months = _.groupBy(weeks, collectGroupedWeeksByMonth);

    // console.log(JSON.stringify(months, null, 2));
    return Object.values(months);
  }

  generateCalendarLayout(entries: Array<any>) {
    // Transform the rows
    const calendar = entries.reduce(this.reducer.bind(this), this.drawer.empty);
    return calendar
  }

  // Reducers (for combining each entry in a calendar layout)
  // Accumulator functions for days, weeks, and months to form a calendar
  // Reducers
  reducer(resultMonth: any, entries: any, monthIndex: number, entriesArr: any): any {
    // Accumulate result - Month
    let monthReduced = entries.reduce((entry: any, week: any, weekIndex: number, weekArr: any) => {
      // Reduce weeks
      let weekReduced = week.reduce((resultDay: any, day: any,) => {
        // Accumulate Result - Day
        resultDay = this.drawer.append(resultDay, this.drawer.valueFor(day));
        resultDay = this.drawer.append(resultDay, this.dividerDay);
        return resultDay;
      }, this.drawer.empty);

      // Construct label
      let specificDayHere = _.first(week);
      let {year, monthName, week: weekNumber} = specificDayHere as any;

      // Add Label
      if (this.addLabel) {
        let sep = " | ";
        let label = [
          year,
          monthName,
          weekNumber.toString().padStart(2, "0"),
          ""].join(sep);
        entry += label
      }

      // Add padding as necessary (before)
      // - Start of Year
      if (weekIndex===0) {
        entry = this.drawer.append(entry, this.drawer.repeat(this.pad, 7 - week.length));
      }

      // Add days
      entry = this.drawer.append(entry, weekReduced);

      // Add padding as necessary (after)
      // - End of Year
      if (weekIndex===entries.length - 1) {
        entry = this.drawer.append(entry, this.drawer.repeat(this.pad, 7 - week.length));
      }

      // Add divider for the week
      entry = this.drawer.append(entry, this.dividerWeek);
      return entry;
    }, this.drawer.empty);

    resultMonth = this.drawer.append(resultMonth, monthReduced);

    // Add divider for month
    // don't add divider if it's the last entry
    if (monthIndex!==entriesArr.length - 1) {
      resultMonth = this.drawer.append(resultMonth, this.dividerMonth);
    }
    return resultMonth;
  };

  setLayoutYearMonthly() {
    this.pad = this.drawer.empty;
    this.dividerDay = this.drawer.empty;
    this.dividerWeek = this.drawer.empty;
    this.dividerMonth = this.drawer.lineBreak;

    if (this.addLabel) {
      // this.dividerMonth = this.drawer.dividerWithLabel;
    }
    this.enableWeekSeparationByMonths();
  }

  setLayoutYearWeekly() {
    this.pad = this.drawer.space;
    this.dividerDay = this.drawer.empty;
    this.dividerWeek = this.drawer.lineBreak;
    this.dividerMonth = this.drawer.divider;

    if (this.addLabel) {
      // this.dividerMonth = this.drawer.dividerWithLabel;
    }
    this.enableWeekSeparationByMonths();
  }

  setLayoutWeekly() {
    this.pad = this.drawer.space;
    this.dividerDay = this.drawer.empty;
    this.dividerWeek = this.drawer.lineBreak;
    this.dividerMonth = this.drawer.empty;

    if (this.addLabel) {
      // this.dividerMonth = this.dividerWithLabel;
    }
    this.disableWeekSeparationByMonths();
  }
}


export interface Drawer<T> {
  empty: T;
  space: T;
  lineBreak: T;
  divider: T;

  append(arr: T, item: T): T;
  prepend(arr: T, item: T): T;

  valueFor(incoming: any): T;
  repeat(pad: T, times: number): T;
}


export class UnicodeDrawer implements Drawer<string>{
  empty: any = "";
  space: any = " ";
  lineBreak: any = "\n";
  divider: any = _.repeat("-", 7) + "\n";

  append(arr: string, item: string): string {
    return arr + item;
  }

  prepend(arr: string, item: string): string {
    return item + arr;
  }

  valueFor(incoming: any): string {
    return "o"
  }

  repeat(pad: string, times: number): string {
    return _.repeat(pad, times);
  }
}

import React from 'react';
import './App.scss';
import {HabitsScene} from "./components/HabitsScene/HabitsScene";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h3>Metrics - Wasif Hyder</h3>
        </header>
        <section>
          <h1>Personal OKRs</h1>
          <HabitsScene />
        </section>
      </div>
    );
  }
}

export default App;

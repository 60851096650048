import {uuid} from "../helpers/helpers";
import _ from "lodash";

export type DateString_YYYY_MM_DD = string;
export type HabitsDataMap = Map<DateString_YYYY_MM_DD, HabitDetails>;

export type m_Habits = m_Habit[];

export class m_Habit {
  id: string;
  order: number;
  name: string;
  // Date indexed (where date is currently a string)
  data: HabitsDataMap;
  visible: boolean;

  constructor(name: string, id?: string, data = {}, order = 0, visible=true) {
      this.id = id || uuid();
      this.order = order; // The order in which the list of habits should be displayed
      this.name = name;
      this.data = data as HabitsDataMap;
      this.visible = visible;
  }

  /**
   *
   * Example: Create a new empty habit()
   * const sampleHabit: m_Habit = m_Habit.newHabit("Migraine");
   *
   * @param name
   */
  static newHabit(name: string): m_Habit {
    return new m_Habit(name)
  }

  static existingHabitWithDefaultValues(habit: m_Habit): m_Habit {
    const existing = habit;
    const withDefaults = new m_Habit(habit.name);
    const withDefaultsFinal = _.merge({}, withDefaults, existing);
    return withDefaultsFinal;
  }
}


// export interface m_Habit{
//     name: string
//     // Date indexed (where date is currently a string)
//     data: HabitsDataMap
// }

export enum HabitValueType {
  Boolean,
  Number
}

// By default we assume it's true or false
export interface HabitDetails {
  value: any,
  valueType?: HabitValueType;
}



import React, {ReactComponentElement} from "react";
import {CalendarLayout, Drawer} from "./CalendarLayout";
import {uuid} from "../../helpers/helpers";


export class CalendarLayoutHTML extends CalendarLayout {
  constructor() {
    super(new ReactDrawer());
  }
}

export class ReactDrawer implements Drawer<Array<any>>{
  empty: any =  [];
  space: any =  <span className="HabitBox hidden" />;
  lineBreak: any =  <span className="newline empty">-</span>;
  divider: any =  <span className="newline">-</span>;
  drawEntry?: Function;

  append(arr: Array<any>, item: Array<any>): Array<any> {
    return arr.concat(item);
  }

  prepend(arr: Array<any>, item: Array<any>): Array<any> {
    return item.concat(arr);
  }

  valueFor(incoming: any): Array<any> {
    let elem: any;
    if (this.drawEntry != null){
      elem = this.drawEntry(incoming);
    } else {
      elem = incoming.value
          ? <span key={uuid()} className="HabitBox checked"></span>
          : <span key={uuid()} className="HabitBox unchecked"></span>
    }
    return [elem];
  }

  repeat(pad: Array<any>, times: number): Array<any> {
    let result: Array<any> = [];
    for(let i = 0; i < times; i++) {
      result.push(pad);
    }
    return result;
  }
}

export enum RenderMode {
  Unicode,
  HTML
}

export enum HabitsLayoutType {
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Year = "Year",
  YearWeek = "Year-Week",
  YearMonth = "Year-Month",
}

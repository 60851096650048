import {m_Habit, m_Habits} from "../model/HabitsDataModel";
import * as habitsData from "../model/data.json";
import _ from "lodash";
import {HabitsDataProvider} from "./APIInterfaces";

export class HabitsDataProviderLocalStorage implements HabitsDataProvider {
  // The place in localstorage where these values are kept
  static HABITS_KEY = "habitsData";

  async getHabits(): Promise<m_Habits> {
    // Check if the value exists on local storage
    const lsHabits = localStorage.getItem(HabitsDataProviderLocalStorage.HABITS_KEY);
    let data: m_Habits;
    if (lsHabits===null || lsHabits===undefined) {
      // Load default set of data
      // @ts-ignore
      data = habitsData.default as m_Habits;
    } else {
      // Load data from local storage
      data = JSON.parse(lsHabits) as m_Habits;
    }

    // Handle Special Case:
    // Currently we've stored all data in local storage but
    // some values do not have an associated id or defaults
    //
    data = data.map(d => m_Habit.existingHabitWithDefaultValues(d)) || [] as m_Habits;
    localStorage.setItem(HabitsDataProviderLocalStorage.HABITS_KEY, JSON.stringify(data));
    return data;
  }

  async updateHabits(habits: m_Habits): Promise<m_Habits> {
    const habitsString = JSON.stringify(habits);

    // Set value in local storage
    localStorage.setItem(HabitsDataProviderLocalStorage.HABITS_KEY, habitsString);
    return habits as m_Habits;
  }

  async deleteHabits(): Promise<boolean> {
    localStorage.removeItem(HabitsDataProviderLocalStorage.HABITS_KEY);
    return true;
  }

  async getHabit(habit: m_Habit): Promise<m_Habit | null> {
    const habits: m_Habits = await this.getHabits();
    for (const h of habits) {
      if (h.id===habit.id) {
        return habit;
      }
    }

    return null;
  }

  async createHabit(habit: m_Habit): Promise<m_Habit> {
    const habits: m_Habits = await this.getHabits();
    // Update with the specified habit added
    this.updateHabits(_.concat(habits, habit));
    return habit;
  }

  // Update the habit with this new object
  async updateHabit(habit: m_Habit): Promise<m_Habit> {
    const habits: m_Habits = await this.getHabits();
    const filteredHabits = _.filter(habits, (h) => h.id!==habit.id);
    // Update with the new habit concatenated
    this.updateHabits(_.concat(filteredHabits, habit));
    return habit;
  }

  async deleteHabit(habit: m_Habit): Promise<boolean> {
    const habits: m_Habits = await this.getHabits();
    const filteredHabits = _.filter(habits, (h) => h.id!==habit.id);
    // Update with the specified habit removed
    this.updateHabits(filteredHabits);
    return true;
  }
}

import React from 'react';
import {m_Habit, m_Habits} from "../../model/HabitsDataModel";
import {Habit} from "./Habit";
import './HabitsScene.scss';
import {HabitsLayoutType} from "../../helpers/types";
import _ from "lodash";
import {getDateToday} from "../../helpers/helpers";
import {HabitsSettingsScene} from "../HabitsSettingsScene/HabitsSettingsScene";
import HabitAPI from "../../api/api";
import {Switch} from "antd";

interface IState {
  habits: m_Habits;
  layout: HabitsLayoutType;
  month: number;
  year: number;
  activeHabit: String;
  displaySettings: boolean;
}


interface IProps {
}

export class HabitsScene extends React.Component<IProps, IState> {
  // @ts-ignore
  constructor(props) {
    super(props);

    // State
    this.state = {
      habits: [],
      layout: HabitsLayoutType.Year,
      month: 1,
      year: getDateToday().year(),
      activeHabit: "All",
      displaySettings: false,
    };
  }

  componentDidMount() {
    HabitAPI.getHabits().then((data: m_Habit[]) => {
      this.setState({habits: data})
    })
  }

  stateSetter(key: any, val: any) {
    const updatedState = Object.assign(this.state, {[key]: val});
    this.setState(updatedState)
  }

  onHabitEntryUpdated(e: any, habitName: string, dateEntry: any) {
    // [] TODO - CLEANUP FUNCTION to be efficient
    let {key} = dateEntry;
    let index = _.findIndex(this.state.habits, habit => habit.name===habitName);
    let existingHabits = _.cloneDeep(this.state.habits);
    let thisHabit = _.cloneDeep(existingHabits[index]);
    let thisHabitData = thisHabit.data;

    // @ts-ignore
    let thisDateEntryHabit = thisHabitData[key];
    let updatedValue = (thisDateEntryHabit && thisDateEntryHabit.value) ? !thisDateEntryHabit.value:true;
    if (thisHabitData) {
      // let existingHabitDataValue = existingHabitData.value || 0;
    }

    // Updated Habit
    thisHabitData = Object.assign({}, thisHabitData, {
      [key]: {"value": updatedValue}
    });

    // Update the habits
    existingHabits[index].data = thisHabitData;

    HabitAPI.updateHabits(existingHabits).then(habits => {
      this.setState({habits: habits});
    });
  }

  private resetData(e: any) {
    HabitAPI.deleteHabits().then(res => {
      if (res) {
        HabitAPI.getHabits().then(habits => {
          this.setState({habits});
        });
      }
    });
  }

  render() {
    const {layout, year, month} = this.state;

    interface HabitsNavigationProps {
      stateKey: string,
      navItems: Array<any>,
      onNavItemClick: Function,
      activeNavItem: any
    };

    const visibleHabits = () => this.state.habits.filter((h: any) => h.visible || h.visible==undefined);

    const HabitsNavigation = ({stateKey, navItems, onNavItemClick, activeNavItem}: HabitsNavigationProps) =>
        <ul className="habitsLayoutLinks">{
          navItems.map((navItem, index) =>
              <li key={index} className={activeNavItem===navItem ? "disabled":""}>
                <a href="#" onClick={(e: any) => {
                  e.preventDefault();
                  onNavItemClick(stateKey, navItem)
                }}>
                  {navItem}
                </a>
              </li>)
        }</ul>
    ;

    let habitsCalendar;
    if (this.state.activeHabit==="All") {
      // Habits Calendar - For Year, Month
      habitsCalendar = visibleHabits().map((habit) =>
          <Habit
              key={habit.name}
              habit={habit}
              layout={layout}
              year={year}
              month={month}
              onUpdate={this.onHabitEntryUpdated.bind(this)}
          />);
    } else {
      habitsCalendar = [2020, 2019, 2018].map((year, indexHabit) =>
          <Habit
              key={indexHabit}
              heading={this.state.activeHabit + " - " + year.toString()}
              habit={this.state.habits[_.findIndex(this.state.habits, habit => habit.name===this.state.activeHabit)]}
              layout={layout}
              year={year}
              month={month}
              onUpdate={this.onHabitEntryUpdated.bind(this)}
          />);
    }

    // [] TODO: Clean up the code by looping through all values properly
    return (
        <section className="Habits">
          <h1>Habits</h1>
          <div className={"settingsToggle"}>
            Settings
            <Switch
                className="toggle"
                defaultChecked={this.state.displaySettings}
                // checkedChildren={"On"}
                // unCheckedChildren={"Off"}
                onChange={(val) => this.setState({displaySettings: val})}
            />
          </div>
          <nav className="HabitsNav">
            <ul className="habitsLayoutLinks">

              <li><a href="#" onClick={this.resetData.bind(this)}>Reset</a></li>
            </ul>
            {Object.entries({ // Navigation Items
              activeHabit: _.concat(["All"], Object.entries(visibleHabits()).map(([k, v]) => v.name)),
              layout: ["Year", "Year-Month", "Year-Week"],
              year: [2020, 2019, 2018],
              // [] TODO: Add monthly view as well
              // month: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            }).map(([navItemKey, navItems]) =>
                <HabitsNavigation
                    key={navItemKey}
                    stateKey={navItemKey}
                    navItems={navItems}
                    onNavItemClick={this.stateSetter.bind(this)}
                    // @ts-ignore
                    activeNavItem={this.state[navItemKey]}
                />)
            }
          </nav>
          {/*<DebugComponent data={this.state} />*/}
          {this.state.displaySettings ?
              <HabitsSettingsScene
                  habits={this.state.habits}
                  onUpdateHabitsSettings={this.onUpdateHabitsSettings.bind(this)}
              />
              :null
          }

          <section className="HabitsList">
            {habitsCalendar}
          </section>
        </section>
    );
  }

  onUpdateHabitsSettings(habits: any) {
    HabitAPI.updateHabitsSettings(habits).then(
        (newHabitsObject: m_Habits) => {
          this.setState({habits: newHabitsObject})
        }
    )
  }
}


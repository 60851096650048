import {DateString_YYYY_MM_DD} from "../model/HabitsDataModel";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export function uuid(){
  return uuidv4();
}

/**
 * DATE Formats
 */
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_MONTH_MMM = "MMM";

export function getDateToday() {
  return moment();
}

export function formatDate(d: any) {
  return d.format(DATE_FORMAT)
}

export function parseDate(inp: string){
  return moment(inp, DATE_FORMAT);
}

export function isEndOfWeek(d: DateString_YYYY_MM_DD) {
  const endOfWeek = parseDate(d).endOf('isoWeek');
  return d===formatDate(endOfWeek);
}

export function isEndOfMonth(d: DateString_YYYY_MM_DD) {
  const endOfMonth = parseDate(d).endOf('month');
  return d===formatDate(endOfMonth);
}

export function isFirstWeekOfMonth(d: DateString_YYYY_MM_DD) {
  const dayOfMonth = parseDate(d).date();

  return Math.ceil(dayOfMonth / 7)===1;
}

/**
 * Date range generators generate the dates starting from a
 * given day and ending on a specific day.
 *
 * Additional helpers simplify getting a range based on
 * year, month, week number, etc etc.
 */

/**
 * rangeWeek gets the range of 7 days starting from the
 * first day of the week all the way to the end of the week
 *
 * @param year
 * @param month
 */
export function* rangeWeek(year: number, weekNo: number) {
  // const startDate = "01-01" + year.toString();
  // const endDate = parseDate(startDate).endOf("isoWeek");
  // return rangeBetweenDates(startDate, endDate)
}

export function rangeMonth(year: number, month: number) {
  const startDate = [year, month.toString().padStart(2, "0"), "01"].join("-");
  const endDate = formatDate(parseDate(startDate).endOf("month"));
  return rangeBetweenDates(startDate, endDate)
}

export function rangeYear(year: number) {
  const startDate = year.toString() + "-01-01";
  const endDate = formatDate(parseDate(startDate).endOf("year"));
  return rangeBetweenDates(startDate, endDate)
}

export function* rangeBetweenDates(start: any, end: any) {
  // Assume start and end dates are provided cocrrectly
  let currentDate = start;
  while (true) {
    yield currentDate;
    if (currentDate===end) {
      break
    }
    currentDate = formatDate(parseDate(currentDate).add(1, 'days'));
  }
}

import React from 'react';
import {m_Habit} from "../../model/HabitsDataModel";
import {CalendarLayoutHTML, ReactDrawer} from "./CalendarLayoutHTML";
import {HabitsLayoutType, RenderMode} from "../../helpers/types";
import {getDateToday, rangeYear, uuid} from "../../helpers/helpers";
import './Habit.scss';
import {CalendarLayout} from "./CalendarLayout";
import _ from "lodash";

interface IProps {
  // Required
  habit: m_Habit

  // Optional
  renderMode?: RenderMode // Determines whether to render in Unicode or HTML
  heading?: string,       // User can add a custom heading, otherwise use habit name
  layout?: HabitsLayoutType // Layout (Year, Month, Week)
  month?: number
  year: number

  // Functions
  onUpdate?: Function
}

interface IState {
}

/**
 * Habit is an element that takes in data for a specific habit
 * and displays it as a habit graph.
 *
 * A Habit graph can be constructed using any
 * - renderMode: Unicode, HTML, SVG
 * - layout:     Year, Month, Week, Day
 * - onUpdate:   Function that updates a given habit entry
 *
 */
export class Habit extends React.Component<IProps, IState> {
  static defaultProps = {
    renderMode: RenderMode.HTML,
    layout: HabitsLayoutType.Year,
    year: getDateToday().year(),
    month: getDateToday().month(),
  };

  calendar: CalendarLayout;

  // @ts-ignore
  constructor(props) {
    super(props);
    this.calendar = new CalendarLayoutHTML();


    // [] TODO: FIXME: This is a very hacky way of adding a value
    // valueForEntry is a function that takes in an incoming value
    // and determines how to display it using the drawer
    //
    // [] TODO: IDEA As an aside, I can use the calendar layout to generate an
    // intermediate representation. And then display that using these
    //
    // Set valueFor on drawer
    (this.calendar.drawer as ReactDrawer).drawEntry = (incomingEntry: any) => {
      // onClick: use the provided on update function to update the habit
      const onClick = (e: any, data: any) => {
        if (this.props.onUpdate) {
          this.props.onUpdate(e, this.props.habit.name, data);
        }
      };

      const className = ["HabitBox", this.props.habit.name].join(" ");
      // [] TODO - FIXME: This naming should be improved to something better
      // [] TODO - FIXME: Should be extracted into a functional component
      // @ts-ignore
      const value = this.props.habit.data[incomingEntry.key];
      if (value && value.value) {
        return <span data-testid='HabitBox' key={incomingEntry.key} onClick={(e: any) => onClick(e, incomingEntry)}
            className={className + " " + "checked"} />;
      } else {
        return <span data-testid='HabitBox' key={incomingEntry.key} onClick={(e: any) => onClick(e, incomingEntry)}
            className={className + " " + "unchecked"} />;
      }
    }
  }

  componentDidMount(): void {

  }

  shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
    // console.log("Should component update?: " + this.props.habit.name + " " + _.isEqual(nextProps, this.props));
    // console.log(JSON.stringify(nextProps, null, 2));
    // console.log(JSON.stringify(this.props, null, 2));
    return !_.isEqual(nextProps, this.props)
  }

  render() {
    // console.log("Rendering Habit Component For: " + this.props.habit.name);

    const {year, layout} = this.props;

    switch (layout) {
      case HabitsLayoutType.Year:
        this.calendar.setLayoutYearWeekly();
        break;
      case HabitsLayoutType.YearMonth:
        this.calendar.setLayoutYearMonthly();
        break;
      case HabitsLayoutType.Month:
        // this.calendar.setLayoutMonthly(); // [] TODO: Implement
        // break;
      default:
        this.calendar.setLayoutWeekly();
    }

    const rangeOfDays = Array.from(rangeYear(year));
    let entries = this.calendar.groupCalendarEntriesFromRange(rangeOfDays);
    let calendarLayout = this.calendar.generateCalendarLayout(entries);
    // console.log(JSON.stringify(entries, null, 2));

    return <article className="Habit">
      <header>
        {this.props.heading || this.props.habit.name}
      </header>
      <div>
        {calendarLayout}
      </div>
    </article>
  }
}

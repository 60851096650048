import React from 'react';
import {m_Habit, m_Habits} from "../../model/HabitsDataModel";
import "../../App.scss";
import "./HabitsSettings.scss";
import {Button, Switch} from 'antd';
import _ from "lodash";
import HabitAPI from "../../api/api";

interface IProps {
  habits: m_Habits
  onUpdateHabitsSettings?: Function,
}

interface IState {
  isEditing: boolean
  habits: any
}

/**
 * Habit is an element that takes in data for a specific habit
 * and displays it as a habit graph.
 *
 * A Habit graph can be constructed using any
 * - renderMode: Unicode, HTML, SVG
 * - layout:     Year, Month, Week, Day
 * - onUpdate:   Function that updates a given habit entry
 *
 */
export class HabitsSettingsScene extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    // Clone habitSettings (but without the data)
    const habitSettingsClone = _.cloneDeep(props.habits).map(({data: any, ...rest}: m_Habit) => {
      return {...rest}
    });

    this.state = {
      isEditing: false,
      habits: habitSettingsClone,
    };
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(this.props.habits, prevProps.habits)) {
      // Update missing data
      const habits = this.props.habits.map(({data, ...habit}: m_Habit, index: number) => {
        return habit;
      });
      // console.log(habits);
      this.setState({habits: habits});
    }
  }

  render() {
    // [x] TODO: Add ability to rename habit
    // [x] TODO: Add ability to add habit
    // [x] TODO: Add ability to hide habit
    // [ ] TODO: Add ability to hide habit - with toggle component
    const habits = Object.values(this.state.habits);

    return <section>
      <section className="HabitsSettings">
        {/*<DebugComponent data={this.state} />*/}
        <h3>Habit Settings</h3>
        <div className="buttonRow">
          <Button data-testid="addHabit" disabled={!this.state.isEditing} type="default" size="small"
              onClick={this.addHabit.bind(this)}>Add a Habit</Button>
          {!this.state.isEditing
              ? ( // Edit
                  <Button
                      data-testid="editHabitsSettings"
                      className="action"
                      type="default"
                      size="small"
                      onClick={this.enableEditing.bind(this)}>
                    Edit
                  </Button>
              )
              :( // Cancel | Save
                  [
                    <Button
                        key="cancel"
                        data-testid="cancelHabitsSettings"
                        className="action"
                        type="default"
                        size="small"
                        onClick={this.cancel.bind(this)}>
                      Cancel
                    </Button>,
                    <Button
                        key="save"
                        data-testid="saveHabitsSettings"
                        className="action"
                        type="primary"
                        size="small"
                        onClick={this.save.bind(this)}>
                      Save
                    </Button>
                  ]
              )
          }
        </div>

        {/* List of existing habits */}
        {habits.map((habit: any, index: number) =>
            <section key={index}>
              <span>⠿</span>
              {!this.state.isEditing
                  ? <div>{habit.name}</div>
                  :<div>
                    {/*  Name Input */}
                    {/* [] TODO - Fix this by replacing name with id */}
                    <input
                        value={habit.name}
                        onChange={(e: any) => this.updateSetting(e.target.value, "name", habit.id)}
                    />
                    <Switch
                        defaultChecked={habit.visible}
                        className="Switch"
                        size="small"
                        onChange={(val: any) => this.updateSetting(val, "visible", habit.id)}
                    />
                  </div>

              }
            </section>
        )}
      </section>
    </section>
  }

  addHabit(e: any) {
    this.setState({
      habits: _.concat(this.state.habits, m_Habit.newHabit("Noobit"))
    })
  }

  save(e: any) {
    this.disableEditing();
    if (this.props.onUpdateHabitsSettings!=null) {
      this.props.onUpdateHabitsSettings(this.state.habits);
    }
  }

  cancel(e: any) {
    this.disableEditing();
  }

  enableEditing() {
    this.setState(Object.assign({}, this.state, {isEditing: true}))
  }

  disableEditing() {
    this.setState(Object.assign({}, this.state, {isEditing: false}))
  }

  updateSetting(val: any, key: string, habitID: any) {
    const updatedHabits = this.state.habits.map((habit: any) => {
      if (habit.id===habitID) {
        return Object.assign({}, habit, {[key]: val})
      }
      return habit;
    });
    this.setState({habits: updatedHabits})
  }
}


import {m_Habit, m_Habits} from "../model/HabitsDataModel";
import {HabitsDataProvider} from "./APIInterfaces";
import {HabitsDataProviderLocalStorage} from "./HabitsLocalStorage";
import _ from "lodash";


/**
 * HabitAPI
 *
 * Habits
 * - Create |
 * - Read   | getHabits, getHabitsByQuery(query)
 * - Update | updateAllHabits(habits)
 * - Delete |
 *
 * Habit
 * - Create | createtHabit(habitID)
 * - Read   | getHabit(habitID), getHabitByName(),
 * - Update | updateHabit(habitID, habitData)
 * - Delete | deleteHabit(habitID)
 *
 * HabitSettings
 * - Create |
 * - Read   | getHabitSettings()
 * - Update | updateHabitSettings()
 * - Delete |
 *
 * HabitSetting
 * - Create |
 * - Read   | getHabitSetting(habitId, habitSettingKey)
 * - Update | updateHabitSetting(habitId, habitSettingKey, habitSettingValue)
 * - Delete |
 *
 */
export class HabitAPI {
  static _habitsDataProvider: HabitsDataProvider = new HabitsDataProviderLocalStorage();

  static setHabitsDataProvider(habitsDataProvider: HabitsDataProvider) {
    this._habitsDataProvider = habitsDataProvider;
  }

  // Get a list of all habits
  static async getHabits(): Promise<m_Habits> {
    return this._habitsDataProvider.getHabits();
  }

  // Update all habits
  static async updateHabits(habits: m_Habit[]) {
    return this._habitsDataProvider.updateHabits(habits)
  }

  // Remove all habits
  static async deleteHabits(): Promise<boolean> {
    return this._habitsDataProvider.deleteHabits();
  }

  // Settings
  static async updateHabitsSettings(habitsSettings: m_Habits): Promise<m_Habits>{
    let lodash = _;
    const habits = await this.getHabits();
    const mergedHabits = Object.values(lodash.merge({}, habits, habitsSettings));
    return this._habitsDataProvider.updateHabits(mergedHabits);
  }

  // Create a specific habit
  static async createHabit(habit = m_Habit.newHabit("Noobit")): Promise<m_Habit> {
    return this._habitsDataProvider.createHabit(habit);
  }

  // Update a specific habit
  static async updateHabit(habit: m_Habit): Promise<m_Habit>{
    return this._habitsDataProvider.updateHabit(habit);
  }

  // Remove a specific habit
  static async deleteHabit(habit: m_Habit): Promise<boolean> {
    return this._habitsDataProvider.deleteHabit(habit);
  }
}

export default HabitAPI;

export const delay = (ms: number) =>
    new Promise(resolve => setTimeout(resolve, ms));
